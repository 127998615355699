let CountUp;
async function initCountUp(el, value) {
    if (!CountUp) {
        CountUp = (await import(/* webpackChunkName: "countup" */ 'countup.js/dist/countUp')).CountUp;
    }

    new CountUp(el, value, {
        separator: '\'',
        decimalPlaces: (value > 0 && value < 10) ? 1 : 0,
    }).start();
}

document.addEventListener('DOMContentLoaded', () => {
    // Initialize the count ups
    const countups = [...document.querySelectorAll('[data-count-up]')];
    if (countups.length) {
        import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/noframework.waypoints').then(() => {
            countups.forEach(el => {
                new Waypoint({
                    element: el,
                    offset: '75%',
                    handler () {
                        if (el.dataset.countUpFinished) {
                            return;
                        }

                        el.dataset.countUpFinished = '1';
                        initCountUp(el, parseFloat(el.dataset.countUp));
                    },
                });
            });
        })
    }

    // Initialize the calculator
    (() => {
        const calculator = document.querySelector('[data-calculator]');

        if (!calculator) {
            return;
        }

        const input = calculator.querySelector('[data-calculator-input]');
        const mainResultInput = calculator.querySelector('[data-calculator-result-main]');
        const results = [...calculator.querySelectorAll('[data-calculator-result]')];

        function calculate() {
            const mainResultValue = parseFloat(input.value || 0) / parseFloat(mainResultInput.dataset.calculatorResultMain);

            mainResultInput.value = mainResultValue.toFixed(1);

            results.forEach(result => {
                initCountUp(result, mainResultValue / parseFloat(result.dataset.calculatorResult));
            });
        }

        input.addEventListener('input', calculate);

        // Set the value from URL hash and calculate the result
        if (window.location.hash) {
            const matches = /^#calculator-meters-(\d+)$/i.exec(window.location.hash);

            if (matches[1] && matches[1] > 0) {
                input.value = matches[1];
                input.focus();
                calculate();
            }
        }
    })();

    // Initialize the calculator form
    [...document.querySelectorAll('[data-calculator-form]')].forEach(form => {
        form.addEventListener('submit', e => {
            e.preventDefault();

            const value = form.querySelector('input[type="number"]').value;
            let redirect = form.action;

            if (value && value > 0) {
                redirect = `${redirect}#calculator-meters-${value}`;
            }

            window.location = redirect;
        });
    });
});
