
document.addEventListener('DOMContentLoaded', () => {
    // Initialize the accordions
    [...document.querySelectorAll('.ce_accordion')].forEach(el => {
        const accordion = el.querySelector('.accordion');

        accordion.style.height = 0;

        el.querySelector('.toggler').addEventListener('click', () => {
            // Open the accordion
            if (!el.classList.contains('active')) {
                // Close all other open siblings
                [...el.parentElement.querySelectorAll('.ce_accordion')].forEach(sibling => {
                    sibling.classList.remove('active');
                    sibling.querySelector('.accordion').style.height = 0;
                });

                el.classList.add('active');
                accordion.style.height = `${accordion.scrollHeight}px`;
            } else {
                // Close the accordion if it is already active
                el.classList.remove('active');
                accordion.style.height = 0;
            }
        });
    });
});
