document.addEventListener('DOMContentLoaded', () => {

    // Initialize the news slider
    const newsSlider = [...document.querySelectorAll('[data-news-slider]')];
    if (newsSlider.length) {
        import(/* webpackChunkName: "swiper" */ 'swiper/core').then(module => {
            const Swiper = module.default;
            Swiper.use([module.EffectFade, module.Navigation, module.Pagination]);

            newsSlider.forEach(el => {
                new Swiper(el.querySelector('.swiper-container'), {
                    slidesPerView: 1,
                    watchOverflow: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    },
                });
            });
        });
    }

    // Initialize the content slider
    const contentSlider = [...document.querySelectorAll('.ce_sliderStart')];
    if (contentSlider.length) {
        import(/* webpackChunkName: "swiper" */ 'swiper/core').then(module => {
            const Swiper = module.default;
            Swiper.use([module.EffectFade, module.Navigation, module.Pagination]);

            contentSlider.forEach(el => {
                new Swiper(el.querySelector('.content-slider'), {
                    slidesPerView: 1,
                    watchOverflow: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },

                    breakpoints: {
                        720: {
                            slidesPerView: 2,
                            spaceBetween: 60
                        },
                        1080: {
                            slidesPerView: 3,
                            spaceBetween: 60
                        }
                    }
                });
            });
        });
    }

    // Header image slider
    const splashs = [...document.querySelectorAll('.splash')];
    if (splashs.length) {

        splashs.forEach(async splash => {
            const images = splash.querySelectorAll('.splash__image');

            if (images.length < 2) {
                return;
            }

            let current = 0;
            setInterval(() => {
                images[current].classList.remove('splash__image--current');
                current = (current + 1) % images.length;
                images[current].classList.add('splash__image--current');
            }, 5000);
        });
    }
})
