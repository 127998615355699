
document.addEventListener('DOMContentLoaded', () => {

    // Initialize the process elements
    [...document.querySelectorAll('[data-process]')].forEach(el => {
        const cssClassImageActive = 'process__image--active';
        const cssClassItemActive = 'process__item--active';
        const cssClassIconChunkActive = 'process-arrow--active';
        const iconChunks = [...el.querySelectorAll('[data-process-icon] .process-arrow')];
        const items = [...el.querySelectorAll('[data-process-item]')];
        const images = [...el.querySelectorAll('[data-process-image]')];
        let currentIndex = 0;

        function showNext() {
            currentIndex++;

            // Reset the index if we reached the last item
            if (currentIndex >= items.length) {
                currentIndex = 0;
            }

            for (let i = 0; i < items.length; i++) {
                // Update the icon chunks
                if (i <= currentIndex) {
                    iconChunks[i].classList.add(cssClassIconChunkActive);
                } else {
                    iconChunks[i].classList.remove(cssClassIconChunkActive);
                }

                // Update the items
                if (i === currentIndex) {
                    images[i].classList.add(cssClassImageActive);
                    items[i].classList.add(cssClassItemActive);
                } else {
                    images[i].classList.remove(cssClassImageActive);
                    items[i].classList.remove(cssClassItemActive);
                }
            }
        }

        items.forEach((item, index) => {
            // Mark the first item as active
            if (index === 0) {
                iconChunks[index].classList.add(cssClassIconChunkActive);
                item.classList.add(cssClassItemActive);
                images[index].classList.add(cssClassImageActive);
            }

            // Power up the "next" link
            item.querySelector('[data-process-item-link]').addEventListener('click', e => {
                e.preventDefault();
                showNext();
            });
        });
    });
});
