document.addEventListener('DOMContentLoaded', () => {

    // Initialize the lightbox
    (async () => {
        const groups = [];

        document.querySelectorAll('a[data-lightbox]').forEach(link => {
            if (!groups.includes(link.dataset.lightbox)) {
                groups.push(link.dataset.lightbox);
            }
        });

        if (!groups.length) {
            return;
        }

        const GLightbox = (await import(/* webpackChunkName: "glightbox" */ 'glightbox/src/js/glightbox')).default;

        groups.forEach(group => {
            GLightbox({
                selector: `[data-lightbox="${ group }"]`,
            });
        });
    })();
})
