import './styles/app.scss';

import 'regenerator-runtime/runtime'

import './scripts/accordion';
import './scripts/calculator';
import './scripts/lightbox';
import './scripts/slider';
import './scripts/process';

document.addEventListener('DOMContentLoaded', () => {
    // Initialize the header navigation trigger
    document.querySelector('[data-navigation-trigger]').addEventListener('click', e => {
        e.preventDefault();
        document.body.classList.toggle('navigation-visible');
    });
});
